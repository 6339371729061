@charset "UTF-8";
.scroll_down{
  span{
    &:before{
      animation:
        scroll_down
        2s
        linear
        infinite;
    }
  }
}
