@charset "utf-8";
.top_modal{
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  margin:auto;
  width: 100%;
  height: 100%;
  background-color: #000;
  z-index: -2;
  box-sizing: border-box;
  opacity: 0;
  .modal_close_btn{
    position: fixed;
    z-index: 2001;
    top: 30px;
    right: 30px;
    width: 31px;
    height: 31px;
    &:hover{
      cursor: pointer;
    }
    .inner{
      position: relative;
      top: 0px;
      left: 0px;
      width: 100%;
      height: 100%;
      span{
        width: 40px;
        height: 3px;
        display: block;
        background-color: #fff;
        position: absolute;
        top: 15px;
        left: -2px;
        &:first-child{
          transform: rotate(45deg);
        }
        &:nth-child(2){
          transform: rotate(-45deg);
        }
      }
    }

  }
  .inner02{
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    box-sizing: border-box;
    align-items: center;
    height: 100%;
    #player02{
      max-width: 1800px;
      width: 100%;
      box-sizing: border-box;
      margin: 0 auto;
    }
  }
}

.top_body{
  .main_modal_open{
    animation:
      modal_open
      0.8s
      cubic-bezier(0.215, 0.61, 0.355, 1)
      forwards;
  }
  .main_modal_close{
    animation:
      modal_close
      0.8s
      cubic-bezier(0.215, 0.61, 0.355, 1)
      forwards;
  }
}

@keyframes modal_open{
  0%{
    opacity: 0;
    z-index: -2;
  }100%{
    opacity: 1;
    z-index: 2000;
  }
}
@keyframes modal_close{
  0%{
    opacity: 1;
    z-index: 2000;
  }
  100%{
    opacity: 0;
    z-index: -2;
  }
}
