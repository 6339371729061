@charset "utf-8";

//ヘッダー
body{
  position: relative;
}
.header_white{
  background-color: #000;
}
.header_black{
  background-color: #fff;
}
#header_out{
  color:#fff;
  a{
    color:#fff;
  }
  text-align: center;
  position: fixed;
  width: 100%;
  height: 86px;
  z-index: 500;
  top: 0;
  left: 0;
  &:before{
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    content:"";
    width: 100%;
    height: 100%;
    background-color: #252525;
    opacity: 0;
    @include transition_header;
  }
  .inner{
    margin: 0 auto;
    position: relative;
    width: 100%;
    height: 100%;

    .header_logo{//ロゴ
      width: 145px;
      position: absolute;
      left: 0;
      right: 0;
      margin: auto;
      top: 34px;
      @include transition_header
      a{
        width: 145px;
        @include transition_header
        opacity: 0.8;
        @include transition01;
        svg{
          height: 22px;
          @include transition01;
          path{
            fill: #fff;
          }
        }
        &:hover{
          opacity: 1;
          @include transition01;
        }
      }
    }
    .header_right_box{
      position: absolute;
      right: 25px;
      top: 25px;
      @include transition_header;
      nav{
        display: flex;
        flex-direction: row;
        align-items: center;
        .language_btn{
          @include font_gramond400;
          @include fontsize(16);
          opacity: 0.8;
          display: block;
          position: relative;
          padding-right: 20px;
          letter-spacing: 0.1em;
          @include transition01;
          @media #{$size_1600}{
            display: none;
          }
          &:hover{
            opacity: 1;
          }
          span{
            display: block;
            margin-top: 7px;
          }
          &:after{
            height: 20px;
            width: 1px;
            content:"";
            display: block;
            right: 0;
            top: 4px;
            background-color: #fff;
            opacity: 0.7;
            position: absolute;
          }
        }
        .sound_btn{
          padding-left: 20px;
          padding-right: 20px;
          display: flex;
          flex-direction: row;
          align-items: center;
          position: relative;
          opacity: 0.8;
          @include transition01;
          @include transition01;
          &:hover{
            cursor: pointer;
            opacity: 1;
            @include transition01;
          }
          @media #{$size_1600}{
            display: none;
          }
          &:after{
            height: 20px;
            width: 1px;
            content:"";
            display: block;
            right: 0;
            top: 2px;
            background-color: #fff;
            opacity: 0.5;
            position: absolute;
            @media #{$size_1600}{
              display: none;
            }
          }
          p{
            @include xs_font01;
            @include fontsize(12);
            @include transition01;
            letter-spacing: 0.2em;
            margin-top: 9px;
            padding-right: 7px;
            width: 90px;
            text-align: left;
          }
          .sound_box01{
            display: flex;
            flex-direction: row;
            align-items: center;

            span{
              display: block;
              width: 5px;
              background-color: #fff;
              @include transition_sound;
              &:nth-child(1){
                height: 13px;
                margin-top: 6px;
                margin-right: 3px;
              }
              &:nth-child(2){
                height: 19px;
                margin-right: 3px;
                margin-top: 0px;
              }
              &:nth-child(3){
                height: 10px;
                margin-top: 9px;
              }
            }
          }

        }
        .sound_btn_off{

          .sound_box01{

            span{
              margin-top: 17px !important;
              @include transition_sound;
              &:nth-child(1){
                height: 3px;

              }
              &:nth-child(2){
                height: 3px;
              }
              &:nth-child(3){
                height: 3px;
              }
            }
          }

        }

        .header_icons{
          display: flex;
          flex-direction: row;
          padding-left: 20px;
          padding-right: 20px;
          @media #{$size_1600}{
            display: none;
          }
          p{
            i{
              @include fontsize(20);
              @include transition01;
            }
            &:nth-child(1){
              margin-right: 22px;
            }
            &:nth-child(2){
              margin-right: 22px;
            }
            &:nth-child(3){

            }
            a{
              opacity: 0.8;
              @include transition01;
              &:hover{
                @include transition01;
                opacity: 1;
              }
            }
          }
        }
        .conv_btn{
          display: flex;
          flex-direction: row;
          @include font_gramond600;
          @include fontsize(13);
          letter-spacing: 0.1em;
          @media #{$size_1000}{
            display: none;
          }
          .btn_out{
            &:nth-child(2){
              margin-left: 10px;
            }
            a{
              display: block;
              background-color: #cc0000;
              height: 36px;
              line-height: 36px;
              padding-left: 17px;
              padding-right: 17px;
              border-radius: 3px;
              &:hover{
                background-color: $hover_color;
                @include transition01;
              }
            }
          }
        }
      }
    }//header_right_box
  }//inner
}//header_out

.menu_btn{
  position: fixed;
  top: 25px;
  left: 30px;
  color:#fff;
  z-index: 3100;
  @include transition_header;
  .menu_btn_box01{

    .menu_btn_box02{
      width: 32px;
      height: 25px;
      position: relative;
      span{
        display: block;
        background-color: #fff;
        width: 32px;
        height: 3px;
        position: absolute;
        margin: auto;
        @include transition01;
        &:first-child{
          top: 0;
          transform-origin: center;
        }
        &:nth-child(2){
          top: 9px;
        }
        &:nth-child(3){
          top: 18px;
          width: 26px;
        }

      }
    }
    p{
      @include xs_font01;
      @include fontsize(10);
      letter-spacing: 0.1em;
      margin-top: -1px;
    }
    .text01{
      display: block;
      opacity: 0.8;
      @include transition01;
    }
    .text02{
      display: none;
    }
    &:hover{
      .menu_btn_box02{
        span{
          opacity: 1;
          @include transition01;
        }
      }
      cursor: pointer;
      .text01{
        opacity: 1;
        @include transition01;
      }
    }
  }
}//menu_btn

.header_black{
  #header_out{
    color:#252525;
    a{
      color:#252525;
    }
    &:before{
      background-color: #fff;
    }
    .inner{
      .header_logo{//ロゴ
        a{
          svg{
            height: 22px;
            path{
              fill:#252525;
            }
          }
        }
      }
      .header_right_box{
        nav{
          .language_btn{
            &:after{
              background-color: #c9c9c9;
              opacity: 1;
            }
          }
          .sound_btn{

            &:after{

              background-color: #c9c9c9;
              opacity: 1;
            }
            .sound_box01{
              span{
                background-color: #252525;
                @include transition_sound;
              }
            }

          }

          .conv_btn{
            display: flex;
            flex-direction: row;
            @include font_gramond600;
            @include fontsize(13);
            letter-spacing: 0.1em;
            @media #{$size_1000}{
              display: none;
            }
            .btn_out{
              &:nth-child(2){
                margin-left: 10px;
              }
              a{
                display: block;
                background-color: #cc0000;
                height: 36px;
                line-height: 36px;
                padding-left: 17px;
                padding-right: 17px;
                border-radius: 3px;
                color:#fff;
                &:hover{
                  background-color: #770000;
                  @include transition01;
                }
              }
            }
          }
        }
      }//header_right_box
    }//inner
  }//header_out
  .menu_btn{
    color:#252525;
    @include transition_header;
    .menu_btn_box01{

      .menu_btn_box02{
        span{
          background-color: #252525;
        }
      }
    }
  }//menu_btn
}//header_black

.body_detail{
  .sound_btn{
    display: none !important;
  }
}



.header_fixed{
  #header_out{
    height: 60px;
    @include transition_header;
    &:before{
      animation:
        header_back_color
        0.7s
        cubic-bezier(0.215, 0.61, 0.355, 1)
        forwards;
    }
    .inner{
      .header_logo{//ロゴ
        width: 120px;
        position: absolute;
        left: 0;
        right: 0;
        margin: auto;
        top: 22px;
        @include transition_header;
        a{
          width: 120px;
          @include transition_header;
          svg{
            height: 18px;
            @include transition01;
          }

        }
      }
      .header_right_box{
        top:15px;
        right: 15px;
        @include transition_header;
        nav{
          .language_btn{
            @include fontsize(14);
            @include transition01;
          }
          .sound_btn{
            p{
              @include fontsize(11);
              @include transition01;
              width: 80px;
            }
          }
          .header_icons{
            @include transition01;
            p{
              padding-top: 2px;
              i{
                @include fontsize(18);
                @include transition01;
              }
              &:nth-child(1){
                margin-right: 18px;
              }
              &:nth-child(2){
                margin-right: 18px;
              }
              &:nth-child(3){

              }
            }
          }//header_icons
          .conv_btn{
            @include fontsize(12);
            .btn_out{
              a{
                height: 30px;
                line-height: 30px;
              }
            }
          }
        }

      }
    }
  }
  .menu_btn{
    top: 18px;
    left: 15px;
    .menu_btn_box01{
      p{
        display: none;
      }
    }
  }
}

@keyframes header_back_color {
  0%{
    opacity: 0;
  }
  100%{
    opacity: 1;
  }

}
