@charset "utf-8";
$img_common:'/common/images/common/';
$size_1900: "only screen and (max-width: 1900px)";
$size_1800: "only screen and (max-width: 1800px)";
$size_1700: "only screen and (max-width: 1700px)";
$size_1600: "only screen and (max-width: 1600px)";
$size_1600min: "only screen and (min-width: 1600px)";
$size_1500: "only screen and (max-width: 1500px)";
$size_1400: "only screen and (max-width: 1400px)";
$size_1300: "only screen and (max-width: 1300px)";
$size_1200: "only screen and (max-width: 1200px)";
$size_1160: "only screen and (max-width: 1160px)";
$size_1100: "only screen and (max-width: 1100px)";
$size_1000: "only screen and (max-width: 1000px)";
$size_960: "only screen and (max-width: 960px)";
$size_900: "only screen and (max-width: 900px)";
$size_800: "only screen and (max-width: 800px)";
$size_700: "only screen and (max-width: 700px)";
$size_600: "only screen and (max-width: 600px)";
$size_500: "only screen and (max-width: 500px)";
$size_480: "only screen and (max-width: 480px)";
$size_400: "only screen and (max-width: 400px)";
$size_300: "only screen and (max-width: 300px)";

$size_min_1000: "only screen and (min-width: 1000px)";

// ex @media #{$size_min_1000}{

//ボタンホバーカラー
$hover_color:#860303;

@mixin fontsize($size: 24, $base: 16) {
	font-size: $size + px;
	font-size: ($size / $base) * 1rem;
}

@mixin font_gramond400{//regular
	font-family: 'EB Garamond', serif;
	font-weight: 400;
}

@mixin font_gramond600{//semibold
	font-family: 'EB Garamond', serif;
	font-weight: 600;
}

@mixin font_gramond700{//bold
	font-family: 'EB Garamond', serif;
	font-weight: 700;
}

@mixin xs_font01{
	font-family: UD Shin Go Conde90 L;
}

@mixin xs_font02{
	font-family: Ryumin Bold KL;
}

@mixin xs_font03{
	font-family: Ryumin Regular KL;
}


@mixin space15{
	box-sizing:border-box;
	padding-left: 15px;
	padding-right: 15px;
}

@mixin space30{
	box-sizing:border-box;
	padding-left: 30px;
	padding-right: 30px;
}





@mixin font01{
	font-family: 'NotoSansCJKjp-Black',sans-serif;/*01*/
}
@mixin font02{
	font-family: 'NotoSansCJKjp-Bold',sans-serif;/*02*/
}
@mixin font03{
	font-family: 'NotoSansCJKjp-DemiLight',sans-serif;/*03*/
}
@mixin font04{
	font-family: 'NotoSansCJKjp-Medium',sans-serif;/*05*/
}
@mixin font05{
	font-family: 'NotoSansCJKjp-Regular',sans-serif;/*06*/
}
@mixin font06{
	font-family: 'NotoSansCJKjp-Thin',sans-serif;/*07*/
}
@mixin font07{
	font-family: 'NotoSansCJKjp-Light',sans-serif;/*04*/
}
@mixin opensans{
	font-family: 'Open Sans', sans-serif;
}

@mixin font_meirio{
	font-family:  "Hiragino Kaku Gothic W3 JIS2004",Quicksand,'メイリオ',Meiryo,"Yu Gothic", YuGothic,'ヒラギノ角ゴ Pro W3','Hiragino Kaku Gothic Pro',sans-serif;
}


@mixin transition01{
	transition: 0.3s cubic-bezier(.165,.84,.44,1);
}

@mixin transition_kuyousai{
	transition: 1.3s cubic-bezier(.165,.84,.44,1);
}

@mixin transition_sound{
	transition: 0.3s linear;
}

@mixin transition02{
	transition: 0.7s cubic-bezier(.165,.84,.44,1);
}

@mixin transition_header{
	transition: 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94);
}
