@charset "UTF-8";
@keyframes line_out_rotate {
  0%{
    transform: rotate(0deg);
  }
  100%{
    transform: rotate(45deg);
  }
}
@keyframes line01 {
  0%{
    top: 0;
    transform: rotate(0deg);
    background-color: #fff;
  }
  50%{
    top: 9px;
    transform: rotate(0deg);
  }
  100%{
    transform: rotate(90deg);
    top:9px;
    background-color: #252525;
  }
}
@keyframes line01_black {
  0%{
    top: 0;
    transform: rotate(0deg);
    background-color: #252525;
  }
  50%{
    top: 9px;
    transform: rotate(0deg);
  }
  100%{
    transform: rotate(90deg);
    top:9px;
    background-color: #252525;
  }
}
@keyframes line02 {
  0%{
    opacity: 1;
    background-color: #fff;

  }
  50%{
    opacity: 1;
  }
  100%{
    opacity: 0;
    background-color: #252525;
  }
}
@keyframes line02_black {
  0%{
    opacity: 1;
    background-color: #252525;

  }
  50%{
    opacity: 1;
  }
  100%{
    opacity: 0;
    background-color: #252525;
  }
}

@keyframes line03 {
  0%{
    top: 18px;
    width: 26px;
    background-color: #fff;

  }
  50%{
    top: 9px;
    width: 32px;
  }
  100%{
    top:9px;
    width: 32px;
    background-color: #252525;
  }
}
@keyframes line03_black {
  0%{
    top: 18px;
    width: 26px;
    background-color: #252525;

  }
  50%{
    top: 9px;
    width: 32px;
  }
  100%{
    top:9px;
    width: 32px;
    background-color: #252525;
  }
}

@keyframes menu_text01 {
  0%{
    opacity: 1;
  }
  100%{
    opacity: 0;
  }
}

@keyframes menu_text02 {
  0%{
    opacity: 0;
  }
  100%{
    opacity: 1;
  }
}

@keyframes line_out_rotate_close {
  0%{
    transform: rotate(45deg);
  }
  100%{
    transform: rotate(0deg);
  }
}
@keyframes linecclose {
  0%{
    top: 9px;
    background-color: #252525;
  }
  100%{
    top:0px;
  }
}

@keyframes line01_close_black {
  0%{
    top: 9px;
    background-color: #252525;
  }
  100%{
    top:0px;
  }
}

@keyframes line02_close {
  0%{
    opacity: 0;
    background-color: #252525;
  }
  100%{
    opacity: 1;
    background-color: #fff;
  }
}

@keyframes line02_close_black {
  0%{
    opacity: 0;
    background-color: #252525;
  }
  50%{
    opacity: 1;
  }
  100%{
    opacity: 1;
    background-color: #252525;
  }
}

@keyframes line03_close {
  0%{
    top: 9px;
    width: 32px;
    background-color: #252525;
  }
  50%{
    top: 9px;
    width: 32px;
  }
  100%{
    top:18px;
    width: 26px;
    background-color: #fff;
  }
}

@keyframes line03_close_black {
  0%{
    top: 9px;
    width: 32px;
    background-color: #252525;
  }
  50%{
    top: 9px;
    width: 32px;
  }
  100%{
    top:18px;
    width: 26px;
    background-color: #252525;
  }
}
