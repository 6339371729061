@charset "UTF-8";

.sp_nav_open{
  animation:
    sp_nav_open
    0.6s
    cubic-bezier(0.215, 0.61, 0.355, 1)
    forwards;
    height: 100vh;
    .inner{
      @media #{$size_min_1000}{
        .sp_menu_box01{
          nav{
            ul{
              li{
              animation:
                sp_nav_li_acticve
                1.2s
                cubic-bezier(0.215, 0.61, 0.355, 1)
                forwards;

              }
              @for $i from 1 to 11 {
                li:nth-child(#{$i}) { animation-delay: ($i * 0.1s); }
              }
            }
          }
        }
        .sp_menu_box02{
          p{
            animation:
              sp_nav_li_acticve
              1.2s
              cubic-bezier(0.215, 0.61, 0.355, 1)
              forwards;

          }
          @for $i from 1 to 4 {
            p:nth-child(#{$i}) { animation-delay: 1 + ($i * 0.1s); }
          }
        }
        .sp_menu_box03{
          nav{
            ul{
              li{
              animation:
                sp_nav_li_acticve
                1.2s
                cubic-bezier(0.215, 0.61, 0.355, 1)
                forwards;

              }
              @for $i from 1 to 3 {
                li:nth-child(#{$i}) { animation-delay: 1.3 + ($i * 0.1s); }
              }
            }
          }
        }
      }
    }
}

.sp_nav_close{
  height: 100vh;
  animation:
    sp_nav_close
    0.6s
    cubic-bezier(0.215, 0.61, 0.355, 1)
    forwards;
}
