@charset "utf-8";
//フッター

.footer_out{
  text-align: center;
  background-color: #000;
  color:#fff;
  padding-top: 130px;
  padding-bottom: 173px;
  padding-left: 15px;
  padding-right: 15px;
  box-sizing: border-box;
  a{
    color:#fff;
    opacity: 0.7;
    @include transition01;
    &:hover{
      opacity: 1;
      @include transition01;
    }
  }
  .inner{
    margin: 0 auto;
    max-width: 1500px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    text-align: left;
    @media #{$size_1400}{
      display: inline-block;
      max-width: 800px;
      margin: 0 auto;
      text-align: center;
    }
    .box01{
      @media #{$size_1400}{
        margin-bottom: 40px;
        text-align: center;
        display: inline-block;
        margin: 0 auto 40px;
      }
      .box04{
        display: flex;
        flex-direction: row;
        margin-bottom: 26px;
        @media #{$size_1000}{
          display: block;
        }
        a{
          display: block;
          width: 165px;
          margin-right: 24px;
          @media #{$size_1000}{
            margin-right: 0;
            margin: 0 auto 20px;
          }
          img{
            width: 100%;
          }
        }
        dl{
          opacity: 0.8;
          dt{
            @include fontsize(14);
            @include xs_font03;
            letter-spacing: 0.1em;
          }
          dd{
            @include fontsize(30);
            @include font_gramond400;
            letter-spacing: 0.07em;
          }
        }
      }//box04
      .box05{
        text-align: center;
        box-sizing: border-box;
        a{
          display: block;
          border:solid 1px #929292;
          width: 424px;
          height: 42px;
          line-height: 42px;
          box-sizing: border-box;
          @include transition01;
          @media #{$size_1000}{
            width:calc(100% - 4px) ;
            max-width: 300px;
            margin-left: 2px;
            margin-right: 2px;
            span{
              box-sizing: border-box;
            }
          }
          &:hover{
            background-color: #fff;
            color:#252525;
            @include transition01;
          }
        }
      }//box05

    }//box01
    .box02{
      display:flex;
      flex-direction: row;
      @media #{$size_1400}{
        margin-bottom: 40px;
        text-align: left;
      }
      @media #{$size_1000}{
        display: block;
        width: 100%;
      }
      nav{
        a{
          display: block;
          @include fontsize(14);
          @include xs_font03;
          margin-bottom: 10px;
          @include transition01;
          &:hover{
            span{
              @include transition01;
            }
          }
          @media #{$size_1000}{
            margin-bottom: 20px;
          }
          span{

          }
        }
        &:first-child,
        &:nth-child(2){
          margin-right: 38px;
        }
      }
    }//box02
    .box03{
      .sns_footer_icon{
        overflow: hidden;
        margin-bottom: 84px;
        @media #{$size_1400}{
          text-align: center;
          margin-bottom: 40px;
        }
        .sns_footer_icon_inner{
          display: flex;
          flex-direction: row;
          float: right;
          @media #{$size_1400}{
            float: none;
            margin: 0 auto;
            text-align: center;
            display: inline-block;
          }
          p{
            padding-left: 13px;
            padding-right: 13px;
            @media #{$size_1400}{
              text-align: center;
              display: inline-block;
            }
            a{
              @include fontsize(28);
              @include transition01;
              &:hover{
                @include transition01;
              }

            }
          }
        }

      }
      .footer_copy{
        @include fontsize(14);
        @include font_gramond400;
        opacity: 0.5;
        @media #{$size_1400}{
          text-align: center;
        }
      }
    }

  }
}
