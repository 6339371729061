@charset "UTF-8";

.header_white{
  .menu_btn_box01_open{
    .menu_btn_box02{
      animation:
        line_out_rotate
        0.3s
        0.2s
        cubic-bezier(0.215, 0.61, 0.355, 1)
        forwards;
      span{
        &:first-child{
          animation:
            line01
            0.3s
            cubic-bezier(0.215, 0.61, 0.355, 1)
            forwards;
        }
        &:nth-child(2){
          animation:
            line02
            0.3s
            cubic-bezier(0.215, 0.61, 0.355, 1)
            forwards;
        }
        &:nth-child(3){
          animation:
            line03
            0.3s
            cubic-bezier(0.215, 0.61, 0.355, 1)
            forwards;
        }
      }
    }
    .text01{
      display: none !important;
    }
    .text02{
      display: block !important;
      margin-left: -2px;
      color:#252525;
    }
  }
}

.header_black{
  .menu_btn_box01_open{
    .menu_btn_box02{
      animation:
        line_out_rotate
        0.3s
        0.2s
        cubic-bezier(0.215, 0.61, 0.355, 1)
        forwards;
      span{
        &:first-child{
          animation:
            line01_black
            0.3s
            cubic-bezier(0.215, 0.61, 0.355, 1)
            forwards;
        }
        &:nth-child(2){
          animation:
            line02_black
            0.3s
            cubic-bezier(0.215, 0.61, 0.355, 1)
            forwards;
        }
        &:nth-child(3){
          animation:
            line03_black
            0.3s
            cubic-bezier(0.215, 0.61, 0.355, 1)
            forwards;
        }
      }
    }
    .text01{
      display: none !important;
    }
    .text02{
      display: block !important;
      margin-left: -2px;
      color:#252525;
    }
  }
}

.header_white{
  .menu_btn_box01_close{
    .menu_btn_box02{
      animation:
        line_out_rotate_close
        0.6s
        cubic-bezier(0.215, 0.61, 0.355, 1)
        forwards;
      span{
        &:first-child{
          top: 9px;
          animation: linecclose 0.6s cubic-bezier(0.215, 0.61, 0.355, 1) forwards;
        }
        &:nth-child(2){
          animation:
            line02_close
            0.6s
            cubic-bezier(0.215, 0.61, 0.355, 1)
            forwards;
        }
        &:nth-child(3){
          top: 9px;
          animation:
            line03_close
            0.6s
            cubic-bezier(0.215, 0.61, 0.355, 1)
            forwards;
        }
      }
    }
  }
}

.header_black{
  .menu_btn_box01_close{
    .menu_btn_box02{
      animation:
        line_out_rotate_close
        0.3s
        cubic-bezier(0.215, 0.61, 0.355, 1)
        forwards;
      span{
        &:first-child{
          top: 9px;
          animation:
            line01_close_black
            0.3s
            cubic-bezier(0.215, 0.61, 0.355, 1)
            forwards;
        }
        &:nth-child(2){
          animation:
            line02_close_black
            0.3s
            cubic-bezier(0.215, 0.61, 0.355, 1)
            forwards;
        }
        &:nth-child(3){
          top: 9px;
          animation:
            line03_close_black
            0.3s
            cubic-bezier(0.215, 0.61, 0.355, 1)
            forwards;
        }
      }
    }
  }
}
