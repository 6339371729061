@charset "utf-8";

.sp_menu{
  position: fixed;
  top: 0;
  left: 0;
  z-index: -1000;
  width: 100%;

  opacity: 0;
  overflow: hidden;
  .inner{
    width: 100%;
    height: 100%;
    background-color: #fff;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content:center;
    align-items: center;
    @media #{$size_1000}{
      display: block;
      padding-top: 100px;
      overflow-y: scroll;
      -webkit-overflow-scrolling: touch;
    }
    .sp_menu_box01{

      @include fontsize(24);
      @include xs_font02;
      height: 350px;
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: center;
      @media #{$size_1000}{
        writing-mode: lr-tb;
        line-height: 2;
        @include fontsize(20);
        margin-bottom: 40px;
        height: auto;
      }
      nav{
        text-align: center;
        margin-bottom: 163px;
        line-height:4;
        display: inline-block;
        width: 100%;
        @media #{$size_1000}{
          margin-bottom: 0px;
        }
        ul{
          margin: 0 auto;
          display: inline-block;
          writing-mode: vertical-rl;
          text-align: left;
          @media #{$size_1000}{
            padding-left: 50px;
            padding-right: 50px;
            box-sizing: border-box;
            text-align: center;
            writing-mode: horizontal-tb;
            line-height: 2;
          }
          li{
            opacity: 0;
            transform: translateY(-10px) rotate(0.0001deg);
            @media #{$size_1000}{
              transform: translateY(0px);
              opacity: 1;
            }
            @media #{$size_1000}{
              margin-bottom: 10px;
            }
            a{
              color:#111;
              @include xs_font02;
              position: relative;
              display: block;
              &:before{
                display: block;
                position: absolute;
                content:"";
                width: 8px;
                height: 8px;
                background-color: #333;
                border-radius: 4px;
                position: absolute;
                top: -21px;
                left: 0;
                right: 0;
                margin: auto;
                opacity: 0;

                @include transition02;
                @media #{$size_1000}{
                  display: none;
                }
              }
              &:hover{
                &:before{
                  opacity: 1;
                  @include transition02;
                }

              }
            }
          }
        }
      }
    }//sp_menu_box01
    .sp_menu_box02{
      display: flex;
      flex-direction: row;
      justify-content: center;
      padding-bottom: 38px;
      @media #{$size_1000}{
        padding-bottom: 40px;
      }
      p{
        display: inline-block;
        padding-left: 13px;
        padding-right: 13px;
        transform: translateY(-10px) rotate(0.0001deg);
        opacity: 0;
        @media #{$size_1000}{
          transform: translateY(0px);
          opacity: 1;
        }

        a{
          i{
            @include fontsize(28);
          }
        }
      }
    }//sp_menu_box02

    .sp_menu_box03{
      ul{
        display: flex;
        flex-direction: row;
        justify-content: center;
        @media #{$size_1000}{
          display: block;
          padding-bottom: 350px;
        }
        li{
          padding-left: 20px;
          padding-right: 20px;
          opacity: 0;
          transform: translateY(-10px) rotate(0.0001deg);
          @media #{$size_1000}{
            transform: translateY(0px);
            opacity: 1;
          }
          @media #{$size_1000}{
            margin-bottom: 10px;
          }
        }
      }
    }//sp_menu_box03


  }

}
