@charset "UTF-8";
@keyframes sp_nav_open {
  0%{
    z-index: -1000;
    opacity: 0;
  }
  100%{
    z-index: 3000;
    opacity: 1;
  }
}

@keyframes sp_nav_close {
  0%{
    z-index: 3000;
    opacity: 1;
    height: 100vh;
  }
  99%{
    height: 100vh;
  }
  100%{
    z-index: -1000;
    opacity: 0;
    height: auto;
  }
}

@keyframes sp_nav_li_acticve {
  0%{
    opacity: 0;
    transform: translateY(-10px) rotate(0.0001deg);
  }
  100%{
    opacity: 1;
    transform: translateY(0) rotate(0.0001deg);
  }
}
