@charset "utf-8";



//パーツ
.d_btn{
  text-align: center;
  a{
    width: 208px;
    height: 58px;
    line-height: 58px;
    background-color: #cc0000;
    color:#fff;
    border-radius: 29px;
    @include font_gramond600;
    @include fontsize(16);
    letter-spacing: 0.1em;
    display: block;
    margin: 0 auto;
    @include transition01;
    &:hover{
      background-color: $hover_color;
      @include transition01;
    }
  }
  button{
    width: 208px;
    height: 58px;
    line-height: 58px;
    background-color: #cc0000;
    color:#fff;
    border-radius: 29px;
    @include font_gramond600;
    @include fontsize(16);
    letter-spacing: 0.1em;
    display: block;
    margin: 0 auto;
    @include transition01;
    border: none;
    outline: none;
    &:hover{
      background-color: $hover_color;
      @include transition01;
      cursor: pointer;
    }
  }
}

.d_btn02{ //お問い合わせフォーム　送信　戻る　ボタン
  text-align: center;
  ul{
    display: inline-block;
    overflow: hidden;
    li{
      padding-left: 5px;
      padding-right: 5px;
      float: left;
      @media #{$size_500}{
        float: none;
        margin-bottom: 20px;
      }
      button{
        width: 208px;
        height: 58px;
        line-height: 58px;
        background-color: #cc0000;
        color:#fff;
        border-radius: 29px;
        @include font_gramond600;
        @include fontsize(16);
        letter-spacing: 0.1em;
        display: block;
        margin: 0 auto;
        @include transition01;
        border: none;
        outline: none;
        &:hover{
          background-color: $hover_color;
          @include transition01;
          cursor: pointer;
        }

      }
      &:nth-child(1){
        button{
          background-color: #555;
          &:hover{
            background-color: #000;
            @include transition01;
            cursor: pointer;
          }
        }
      }
    }
  }
}

.v_h2_title_out{
  text-align: center;
  h2{
    writing-mode: vertical-rl;
    text-align: left;
    @include fontsize(24);
    @include xs_font02;
    margin: 0 auto;
    display: inline-block;
    white-space: nowrap;
  }
}

.view_more_btn01_out{
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  .view_more_btn01{
    position: relative;
    span{
      @include fontsize(24);
      @include font_gramond600;
      position: relative;
      text-align: left;
      display: block;
      @include transition01;
    }
    &:before{
      content:"";
      display: block;
      width: 189px;
      height: 1px;
      background-color: #c9c9c9;
      position: absolute;
      top: 16px;
      left: -200px;
      transform-origin: right;
      @include transition01;
      @media #{$size_1000}{
        width: 120px;
        left: -130px;
      }
    }
    &:hover{
      @include transition01;
      span{
        transform: translateX(40px);
        @include transition01;
        @media #{$size_1000}{
          transform: translateX(0px);
        }

      }
      &:before{
        transform-origin: right;
        width: 229px;
        @include transition01;
        @media #{$size_1000}{
          width: 120px;
        }

      }
    }
  }
}

.scroll_down{
  position: absolute;
  left: 0;
  right: 0;
  margin: auto;
  bottom: 72px;
  width: 120px;
  text-align: center;
  @include transition01;
  @media #{$size_1000}{
    display: none;
  }
  span{
    width: 20px;
    height: 40px;
    border-radius: 10px;
    border: solid 1px ;
    border-color: rgba(255,255,255,0.5);
    display: block;
    margin: 0 auto 6px auto;
    position: relative;
    opacity: 0.6;
    @include transition01;
    &:before{
      position: absolute;
      display: block;
      width: 4px;
      height: 4px;
      border-radius: 2px;
      background-color: #fff;
      top: 7px;
      left: 0;
      right: 0;
      margin:auto;
      content:"";
      opacity: 0.6;
      @include transition01;
    }
  }
  p{
    color:#fff;
    @include fontsize(12);
    @include font_gramond400;
    opacity: 0.6;
    @include transition01;
    letter-spacing: 0.1em;
  }
  &:hover{
    cursor: pointer;
    span{
      opacity: 1;
      @include transition01;
      &before{
        opacity: 1;
        @include transition01;
      }
    }
    p{
      opacity: 1;
      @include transition01;
    }

  }
}


.scroll_down--black{
  span{
    border-color: #333;
    &:before{
      background-color: #333;
    }
  }
  p{
    color:#333;
    opacity: 1;
  }
}
.v_small_arrow{
  padding-top: 14px;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;

  .arrow_inner{
    width: 140px !important;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    position: relative;
    span{
      @include font_gramond400;
      @include fontsize(18);
      letter-spacing: 0.1em;
      position: relative;
      text-align: right;
      @include transition01;
    }
    &:before{
      content:"";
      display: block;
      position: absolute;
      top: 12px;
      left: -130px;
      width: 130px;
      height: 1px;
      background-color: #d6d6d6;
      @include transition01;
      transform-origin: right;

    }
  }
}

a{
  &:hover{
    .v_small_arrow{
      span{
        transform: translateX(30px);
        @include transition01;

      }
      .arrow_inner{
        &:before{
          @include transition01;
          transform-origin: right;
          width: 160px;
        }
      }
    }
  }
}

//ページャー
.parts_pager01{
  display: inline-block;
  overflow: hidden;
  padding-top: 60px;
  li{
    padding-left: 5px;
    padding-right: 5px;
    float: left;
    @include fontsize(14);
    @include xs_font01;
    span{
      background-color: #000;
      color:#fff;
      width: 30px;
      height: 30px;
      display: block;
      line-height: 30px;
    }
    a{
      background-color: #ccc;
      display: block;
      width: 30px;
      height: 30px;
      line-height: 30px;
      @include transition01;
      &:hover{
        background-color: #000;
        color:#fff;
        @include transition01;
      }
    }
  }
}
