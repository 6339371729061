@charset "utf-8";

body{
  @media #{$size_1000}{

  }
}
.top_main_img{
  width: 100%;
  height: 100vh;
  text-align: center;
  position: relative;
  @media #{$size_1100}{
    z-index: 0;
    background-image: url(/assets/images/top/main_sp.jpg);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: top center;
    .scroll_down{
      display: block;
      bottom: 30px;
    }
  }
  .inner{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    max-width: 1370px;
    margin: 0 auto;

    @media #{$size_1600}{
      max-width: 1000px;
    }
    @media #{$size_1200}{
      max-width: 800px;
    }
    @media #{$size_1100}{
      display: block;

      max-width: 100%;
    }
    .main_text{
      width: 100%;
      @media #{$size_1100}{
        height: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
      }
      .main_text_inner{
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content:space-between;
        width: 100%;
        @media #{$size_1100}{
          display: block;
          text-align: center;
          padding-bottom: 80px;
          padding-left: 30px;
          padding-right: 30px;
          box-sizing: border-box;
        }
        .main_text_h1{
          width: 406px;
          @media #{$size_1200}{
            width: 300px;
          }
          img{
            width: 100%;
          }
          @media #{$size_1100}{
            width: 100%;
            max-width: 400px;
            margin: 0 auto;
            padding-top: 40px;
            padding-bottom: 40px;
            padding-left: 15px;
            padding-right: 15px;
            box-sizing: border-box;
          }
        }
        .watch_the_video{
          display: flex;
          flex-direction: row;
          align-items: center;
          padding-top: 50px;
          padding-bottom: 50px;
          padding-left: 120px;
          position: relative;
          @include transition01;
          @media #{$size_1100}{
            text-align: center;
            padding-left: 0;
            padding-top: 0;
            padding-bottom: 0;
          }

          p{
            color:#fff;
            @include fontsize(20);
            @include font_gramond400;
            letter-spacing: 0.2em;
            position: relative;
            padding-right: 70px;
            right: 0;
            @media #{$size_1100}{
              width: 100%;
              max-width: 220px;
              border: solid 1px #fff;
              padding-right: 0;
              margin: 0 auto;
              height: 50px;
              line-height: 50px;
              border-radius: 25px;
              @include fontsize(16);
              letter-spacing: 0.1em;
            }
            b{
              font-weight: normal;
              @include transition01;
              opacity: 0.8;
              &:first-child{
                display: block;
                @media #{$size_1100}{
                  display: none;
                }
              }
              &:nth-child(2){
                display: none;
                @media #{$size_1100}{
                  display: block;
                  margin: 0;
                }
              }
            }
            &:before{
              content:"";
              display: block;
              position: absolute;
              top: 14px;
              left: -91px;
              background-color: #fff;
              opacity: 0.5;
              width: 66px;
              height: 1px;
              @include transition01;
              @media #{$size_1100}{
                display: none;
              }
            }
          }
          span{
            display: block;
            width: 48px;
            height: 48px;
            margin-left: 21px;
            position: absolute;
            top: 40px;
            right: 0;
            @include transition01;
            img{
              width: 100%;
              opacity: 0.6;
              @include transition01;
            }
            @media #{$size_1100}{
              display: none;
            }
          }

        }//.watch_the_video
        .watch_the_video_hover{
          &:hover{
            cursor: pointer;
            p{
              b{
                transform: translateX(30px);
                opacity: 1;
                @include transition01;
                @media #{$size_1100}{
                  transform: translateX(0px);
                }
              }
              &:before{
                width: 96px;
                @include transition01;
                opacity: 1;
              }
            }
            span{
              transform: translateX(30px);
              @include transition01;
              img{
                opacity: 1;
                @include transition01;
              }
            }
          }
        }
      }
    }
  }//inner

  #wrapper {
      width: 100%;
      min-height: 100%;
      overflow: hidden;
      position: absolute;
      z-index: -2;
      &:before{
        content:"";
        display: block;
        background: linear-gradient(180deg, rgba(37, 37, 37, 0) 0%, #000 100%);
        position: absolute;
        z-index: 0;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 500px;
        animation:
          m_opacity
          2s
          5s
          cubic-bezier(0.215, 0.61, 0.355, 1)
          forwards;
      }
      @media #{$size_1100}{
        height: auto !important;
        min-height: auto !important;
        position: static;
        display: block;
        z-index: 1;
        display: none;
      }
      #player {
          position: relative;
          z-index: -1;
          @media #{$size_1100}{
            width: 100% !important;
            margin-left: 0 !important;
            top: 0;
            display: none;
          }
      }
  }
  .main_copy{
    position: absolute;
    bottom:38px;
    right:43px;
    @include fontsize(12);
    @include font_gramond400;
    color:#fff;
    opacity: 0.8;
    @media #{$size_1100}{
      display: none;
    }
  }


}//top_main_img

@keyframes m_opacity {
  0%{
    opacity: 1;
  }
  100%{
    opacity: 0;
  }
}

.concept_out{
  background-color: #fff;
  padding-top: 315px;
  padding-bottom: 295px;
  @media #{$size_1000}{
    background-color: #f5f5f5;
    padding-top: 80px;
    padding-bottom: 80px;
  }
  @media #{$size_1000}{
    text-align: center;
    padding-left: 30px;
    padding-right: 30px;
    box-sizing: border-box;
  }
  .inner{
    text-align: center;

    @media #{$size_1000}{
      max-width: 500px;
      margin: 0 auto;
    }

    .h2_out{
      display: block;
      width: 100%;

      .inner02{
        writing-mode: vertical-rl;
        text-align: left;
        margin: 0 auto;
        line-height: 3.2;
        //min-height: 520px;
        display: inline-block;
        height: 34em;
        width: 460px;
        @media #{$size_1000}{
          writing-mode:horizontal-tb;
          height: auto;
          width: auto;
        }
      }h2{
        @include fontsize(24);
        padding-left: 26px;
        letter-spacing: 0.2em;
        @include xs_font02;
        @media #{$size_1000}{
          padding-left: 0;
        }
      }
      p{
        @include xs_font03;

        &:nth-child(2){
          padding-left: 52px;
          @media #{$size_1000}{
            padding-left: 0;
          }

        }
      }
      span{
        display: block;
        @include fontsize(16);
        letter-spacing: 0.2em;
        @media #{$size_1000}{
          display: inline;
        }
      }
    }
  }
}

.top_hina_out{
  background-color: #252525;
  color:#fff;
  padding-top: 180px;
  padding-left: 30px;
  padding-right: 30px;
  box-sizing: border-box;
  @media #{$size_1000}{
    padding-top: 80px;
    text-align: center;
    padding-bottom: 80px;
  }
  .inner{
    @media #{$size_1000}{
      max-width: 500px;
      margin: 0 auto;
      text-align: center;
      .inner02{
        margin: 0 auto;
      }
      .inner03{
        text-align: center;
      }

    }
    .h2_out{
      display: inline-block;
      height: 310px;
      width: 100%;
      text-align: center;
      @media #{$size_1000}{
        height: auto;
      }
      h2{
        @include fontsize(72);
        font-family: Reimin Medium;
        writing-mode: vertical-rl;
        text-align: center;
        margin: 0 auto;
        line-height: 1.4;
        font-family: Reimin Medium;
        margin-bottom: 70px;
        letter-spacing: 0.1em;
        display: inline-block;
        @media #{$size_1000}{
          writing-mode:horizontal-tb;
          line-height: 1.4;
          @include fontsize(36);
          margin-bottom: 35px;
        }
      }
    }
    .text01{
      writing-mode: vertical-rl;
      text-align: left;
      margin: 0 auto;
      line-height: 3.2;
      @include xs_font02;
      height: 390px;
      letter-spacing: 0.1em;
      margin-bottom: 45px;
      width: 430px;
      display: inline-block;
      @media #{$size_1000}{
        writing-mode:horizontal-tb;
        margin-bottom: 0;
        height: auto;
        width: auto;
      }
      h3{
        @include fontsize(24);
        padding-left: 26px;
        @media #{$size_1000}{
          padding-left: 0;
        }
      }
      p{
        @include fontsize(16);
        @include xs_font03;
        @media #{$size_1000}{
          margin-bottom: 30px;
        }
      }
    }
    .btn_out{
      a{
        @include fontsize(16);
      }
      @media #{$size_1000}{
        margin-bottom: 40px;
      }
    }
    .hina_pic{
      margin-top: -200px;
      max-width: 1331px;
      margin: -200px auto 0;
      @media #{$size_1000}{
        margin: 0 auto;
      }
      img{
        width: 100%;
      }
    }
  }
}

.hina_oder_out{
  position: relative;
  margin-top: -70px;

  @media #{$size_1100}{
    position: relative;
    z-index: 0;
    margin-top: -50px;
    padding-left: 15px;
    padding-right: 15px;
    box-sizing: border-box;
    .sp_back{
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      padding-bottom: 80px;
      padding-top: 80px;
      background-image: url(/assets/images/top/sp_back01.jpg);
      background-size: cover;
      background-position: top center;
      z-index: -1;
      &:before{
        content:"";
        position: absolute;
        top: 0;
        left: 0;
        display: block;
        width: 100%;
        height: 100px;
            background:
            linear-gradient(0deg, rgba(37, 37, 37, 0) 0%,
            rgba(37, 37, 37, 1) 100%);
      }
      &:after{
        content:"";
        position: absolute;
        bottom: 0;
        left: 0;
        display: block;
        width: 100%;
        height: 100px;
            background:
            linear-gradient(180deg, rgba(37, 37, 37, 0) 0%,
            rgba(37, 37, 37, 1) 100%);
      }
    }
  }
  .box01{
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    color:#fff;
    overflow: hidden;
    @media #{$size_1100}{
      position: static;
      padding-top: 80px;
    }
    .inner{
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      max-width: 1000px;
      width: 100%;
      @media #{$size_1300}{
        max-width: 800px;
      }
      @media #{$size_1100}{
        display: block;
        max-width: 500px;
      }
      .left{
        max-width: 600px;
        text-align: left;
        h2{
          @include fontsize(62);
          @include xs_font03;
          margin-bottom: 17px;
          @media #{$size_1100}{
            @include fontsize(36);
          }
        }
        h3{
          @include fontsize(30);
          @include xs_font03;
          line-height: 1.5;
          margin-bottom: 23px;
          @media #{$size_1100}{
            @include fontsize(24);
          }
        }
        p{
          @include fontsize(16);
          @include xs_font03;
          line-height: 2.2;
          opacity: 0.8;
          margin-bottom: 40px;
        }
      }
      .right{
        max-width: 290px;
        @media #{$size_1100}{
          text-align: center;
          width: 100%;
          margin: 0 auto;
        }
        .btn_out{
          margin-bottom: 30px;
        }
        p{
          @include fontsize(18);
          @include xs_font03;
          @media #{$size_1100}{
            text-align: center;
          }
        }
      }
    }

  }
  .od_v_out{
    overflow: hidden;
    position: relative;
    @media #{$size_1100}{
      position: absolute;
      z-index: -1;
      top: 0;
      left: 0;
      width: 100%;
    }
    &:before{
      content:"";
      position: absolute;
      top: 0;
      left: 0;
      display: block;
      width: 100%;
      height: 300px;
          background:
          linear-gradient(0deg, rgba(37, 37, 37, 0) 0%,
          rgba(37, 37, 37, 1) 100%);
    }
    &:after{
      content:"";
      position: absolute;
      bottom: 0;
      left: 0;
      display: block;
      width: 100%;
      height: 400px;
          background:
          linear-gradient(180deg, rgba(37, 37, 37, 0) 0%,
          rgba(37, 37, 37, 1) 100%);
    }
    video{
      width: 100%;
      @media #{$size_1100}{
        display: none;
      }
    }
  }
  .od_v_out02{
    overflow: hidden;
    position: relative;
    width: 100%;
    height: calc(100vw * 0.7);
    box-sizing: border-box;
    background-image: url(/assets/images/top/back02.jpg);
    padding-top: 200px;
    padding-bottom: 200px;
    background-repeat: no-repeat;
    background-size: cover;
    // @media #{$size_1100}{
    //   position: absolute;
    //   z-index: -1;
    //   top: 0;
    //   left: 0;
    //   width: 100%;
    //
    // }
    @media #{$size_1100}{
      display: none;
    }
    &:before{
      content:"";
      position: absolute;
      top: 0;
      left: 0;
      display: block;
      width: 100%;
      height: 300px;
          background:
          linear-gradient(0deg, rgba(37, 37, 37, 0) 0%,
          rgba(37, 37, 37, 1) 100%);
    }
    &:after{
      content:"";
      position: absolute;
      bottom: 0;
      left: 0;
      display: block;
      width: 100%;
      height: 400px;
          background:
          linear-gradient(180deg, rgba(37, 37, 37, 0) 0%,
          rgba(37, 37, 37, 1) 100%);
    }
  }
}



.top_other{
  background-color: #fff;
  padding-top: 186px;
  padding-bottom: 186px;
  padding-left: 15px;
  padding-right: 15px;
  box-sizing: border-box;
  text-align: center;
  @media #{$size_1000}{
    padding-top: 250px;
    padding-left: 30px;
    padding-right: 30px;
  }
  .inner{
    max-width: 1400px;
    margin: 0 auto;
    @media #{$size_1600}{
      max-width: 850px;
    }
    h2{
      writing-mode: vertical-rl;
      text-align: left;
      @include fontsize(24);
      @include xs_font02;
      margin: 0 auto 176px auto;
      @media #{$size_1000}{
        writing-mode:horizontal-tb;
        max-width: 500px;
        margin-bottom: 40px;
      }
    }
    .box01{
      width: 100%;
      display: flex;
      flex-direction: row;
      @media #{$size_1000}{
        width: 100%;
        max-width: 500px;
        display: block;
        margin: 0 auto;
      }
      .left{
        width: 66%;
        @media #{$size_1000}{
          width: 100%;
          margin-bottom: 60px;
        }
      }
      .right{
        width: 34%;
        @media #{$size_1000}{
          width: 100%;
        }
      }
      .box02{
        position: relative;

        h3{
          @include xs_font02;
          @include fontsize(36);
          position: absolute;
          @media #{$size_1000}{
            @include fontsize(28);
          }
        }
        a{
          &:hover{
            img{
              opacity: 1;
            }
          }
        }


      }
      .box03{
        width: calc(100% - 80px);
        padding-top: 39px;
        @media #{$size_1000}{
          width: 100%;
        }
        img{
          width: 100%;
        }
        h3{
          @include fontsize(48);
          top:160px;
          left: -33px;
          @media #{$size_1600}{
            top:100px;
            @include fontsize(36);
          }
          @media #{$size_1000}{
            top:110px;
            left: -17px;
            @include fontsize(28);
          }
        }

      }
      .box04{
        width: 100%;
        margin-bottom: 123px;
        h3{
          left: -19px;
          top: 11px;
          @media #{$size_1600}{
            top: 0px;
          }
          @media #{$size_1000}{
            top: 22px;
            left: -17px;
          }
        }
        img{
          width: 100%;
        }
      }
      .box05{
        width: 100%;
        h3{
          left: -19px;
          top: -27px;
          @media #{$size_1000}{
            top: -20px;
            left: -17px;
          }
        }

        img{
          width: 100%;
        }
      }
    }
    .box06{
      overflow: hidden;
      padding-top: 70px;
      width: 100%;
      display: flex;
      flex-direction: row;
      @media #{$size_1000}{
        width: 100%;
        max-width: 500px;
        margin: 0 auto;
        display: block;
        overflow: visible;
      }

      .inner02{
        width: 50%;
        @media #{$size_1000}{
          width: 100%;
        }
        &:after{
          content:"";
          width: 0;
          height: 0;
          clear: both;
          display: table;
        }
        .box07{
          float: right;
          width: calc(100% - 190px);
          //margin-right: -237px;
          position: relative;
          margin-right: -237px;
          @media #{$size_1000}{
            width: 100%;
            margin-right: 0;
          }
          a{
            width: 100%;
            display: block;

            &:hover{
              img{
                opacity: 1;
                width: 100%;
              }
            }
          }

          h3{
            @include xs_font02;
            @include fontsize(36);
            position: absolute;
            left: -19px;
            top: -27px;
            @media #{$size_1000}{
              @include fontsize(28);
                top: -20px;
                left: -17px;

            }
          }
          div{
            width: 100%;
            img{
              width: 100%;
            }
          }

        }

      }
      .box08{
        width: 50%;
        @media #{$size_1000}{
          width: 100%;
          display: none;
        }
      }
    }
  }//inner
}









.top_other02{
  background-color: #fff;
  padding-top: 186px;
  padding-bottom: 186px;
  padding-left: 15px;
  padding-right: 15px;
  box-sizing: border-box;
  text-align: center;
  @media #{$size_1000}{
    padding-top: 250px;
    padding-left: 30px;
    padding-right: 30px;
  }
  .inner{
    max-width: 1400px;
    margin: 0 auto;
    @media #{$size_1600}{
      max-width: 850px;
    }
    h2{
      writing-mode: vertical-rl;
      text-align: left;
      @include fontsize(24);
      @include xs_font02;
      margin: 0 auto 176px auto;
      @media #{$size_1000}{
        writing-mode:horizontal-tb;
        max-width: 500px;
        margin-bottom: 40px;
      }
    }
    .box01{
      width: 100%;
      display: flex;
      flex-direction: row;
      @media #{$size_1000}{
        width: 100%;
        max-width: 500px;
        display: block;
        margin: 0 auto;
      }
      .left{
        width: 66%;
        @media #{$size_1000}{
          width: 100%;
          margin-bottom: 100px;
        }
      }
      .right{
        width: 34%;
        @media #{$size_1000}{
          width: 100%;
        }
      }
      .box02{
        position: relative;

        h3{
          @include xs_font02;
          @include fontsize(36);
          position: absolute;
          @media #{$size_1000}{
            @include fontsize(28);
          }
        }
        a{
          transition: all 0.1s;
          &:hover{
            background-color: #e9e9e9;
            img{
              opacity: 1;
            }
          }
        }


      }
      .box03{
        width: calc(100% - 80px);
        padding-top: 39px;
        @media #{$size_1000}{
          width: 100%;
        }
        a{
          display: block;
          width: 100%;
          height: 525px;
          background-color: #f1f1f1;
          position: relative;
          @media #{$size_1600}{
            height: 300px;
          }
          @media #{$size_1000}{
            height: 300px;
          }
          .box03_image{
            width: 398px;
            position: absolute;
            left: 0;
            right: 0;
            margin:auto;
            bottom: 0;
            @media #{$size_1600}{
              width:200px;
            }
            img{
              width: 100%;
            }
          }
          h3{
            @include fontsize(48);
            top:-40px;
            left: -33px;
            @media #{$size_1600}{

              @include fontsize(36);
              top:-25px;
              left: -25px;
            }
            @media #{$size_1000}{

              @include fontsize(28);
              top: -20px;
              left: -20px;
            }
          }
          .v_small_arrow{
            position: absolute;
            bottom: -40px;
            right:0px;;
          }
        }

      }
      .box04{
        width: 100%;
        margin-bottom: 123px;
        @media #{$size_1000}{
          margin-bottom: 100px;
        }
        a{
          display: block;
          width: 100%;
          height: 290px;
          background-color: #f1f1f1;
          position: relative;
          @media #{$size_1600}{
            height: 180px;
          }
          @media #{$size_1000}{
            height: 300px;
          }
          h3{
            top:-30px;
            left: -25px;
            @media #{$size_1600}{
              top: -25px;
            }
            @media #{$size_1000}{
              top:-25px;
              left: -25px;
            }
          }
          .box04_image{
            width: 143px;
            position: absolute;
            left: 0;
            right: 0;
            margin:auto;
            bottom: 12px;
            @media #{$size_1600}{
              width: 80px;
              left: auto;
              right: 50px;
            }
            @media #{$size_1000}{
              width: 123px;
              left: 0;
              right: 0;
            }
            img{
              width: 100%;
            }
          }
          .v_small_arrow{
            position: absolute;
            bottom: -40px;
            right:0px;;
          }
        }
      }
      .box05{
        width: 100%;

        a{
          display: block;
          width: 100%;
          height: 290px;
          background-color: #f1f1f1;
          position: relative;
          @media #{$size_1600}{
            height: 180px;
          }
          @media #{$size_1000}{
            height: 300px;
          }
          h3{
            left: -19px;
            top: -27px;
            @media #{$size_1600}{
              top: -25px;
            }
            @media #{$size_1000}{
              top:-25px;
              left: -25px;
            }
          }

          .box05_image{
            width: 211px;
            position: absolute;
            left: 0;
            right: 0;
            margin:auto;
            bottom: 30px;
            @media #{$size_1600}{
              width: 140px;
              bottom: 15px;
            }
            @media #{$size_1000}{
              width: 211px;
              bottom: 35px;
            }
            img{
              width: 100%;
            }
          }
          .v_small_arrow{
            position: absolute;
            bottom: -40px;
            right:0px;;
          }
        }
      }
    }
    .box06{
      overflow: hidden;
      padding-top: 70px;
      width: 100%;
      display: flex;
      flex-direction: row;
      @media #{$size_1000}{
        width: 100%;
        max-width: 500px;
        margin: 0 auto;
        display: block;
        overflow: visible;
        padding-top: 100px;
      }

      .inner02{
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: center;
        @media #{$size_1000}{
          width: 100%;
        }
        &:after{
          content:"";
          width: 0;
          height: 0;
          clear: both;
          display: table;
        }
        .box07{
          width: 546px;
          //margin-right: -237px;
          position: relative;
          padding-left: 40px;
          padding-right: 40px;
          box-sizing: border-box;
          @media #{$size_1600}{
            width: 289px;
            padding-left: 0;
            padding-right: 0;
          }
          @media #{$size_1000}{
            width: 100%;
            margin-right: 0;
          }
          a{
            width: 100%;
            display: block;
            height: 290px;
            background-color: #f1f1f1;
            position: relative;
            margin-bottom: 39px;
            transition: all 0.1s;
            @media #{$size_1600}{
              height: 180px;
            }
            @media #{$size_1000}{
              height: 300px;
            }
            .box07_image{
              width: 245px;
              position: absolute;
              left: 0;
              right: 0;
              margin:auto;
              bottom: 0px;
              img{
                width: 100%;
              }
              @media #{$size_1600}{
                width: 160px;
              }
              @media #{$size_1000}{
                width: 245px;
              }
            }
            .v_small_arrow{
              position: absolute;
              bottom: -40px;
              right:0px;;
            }
            &:hover{
              background-color: #e9e9e9;
              img{
                opacity: 1;
                width: 100%;
              }
            }
          }

          h3{
            @include xs_font02;
            @include fontsize(36);
            position: absolute;
            left: -19px;
            top: -27px;
            @media #{$size_1600}{
              top: -25px;
            }
            @media #{$size_1000}{
              @include fontsize(28);
              top:-25px;
              left: -25px;

            }
          }
          div{
            width: 100%;
            img{
              width: 100%;
            }
          }

        }

      }
      .box08{
        width: 50%;
        @media #{$size_1000}{
          width: 100%;
          display: none;
        }
      }
    }
  }//inner
}

































.top_news_out{
  background-color: #f5f5f5;
  padding-top: 182px;
  padding-bottom: 308px;
  text-align: center;
  @media #{$size_1000}{
    padding-top: 80px;
    padding-bottom: 80px;
    padding-left: 30px;
    padding-right: 30px;
    box-sizing: border-box;
  }
  h2{
    margin-bottom: 130px;
    @media #{$size_1000}{
      margin-bottom: 40px;
      writing-mode:horizontal-tb;
      max-width: 500px;
      margin: 0 auto 40px auto;
    }
  }
  .inner{

    max-width: 1400px;
    margin: 0 auto;
    @media #{$size_1600}{
      max-width: 1000px;
    }
    .box01{
      display: flex;
      flex-direction: row;
      @media #{$size_1000}{
        max-width: 500px;
        display: block;
        margin: 0 auto;
      }
      article{
        width: 33.33333333%;
        padding-left: 30px;
        padding-right: 30px;
        @media #{$size_1000}{
          width: 100%;
          margin-bottom: 40px;
          padding: 0;
        }
        &:nth-child(2){
          padding-top: 72px;
          @media #{$size_1000}{
            padding-top: 0;
          }
        }
        a{
          display: block;
          width: 100%;
          div{
            margin-bottom: 25px;
            img{
              width: 100%;
              filter : grayscale(100%);
              @include transition01;
            }
          }
          h3{
            text-align: left;
            @include fontsize(16);
            @include xs_font03;
            margin-bottom: 11px;
          }
          p{
            text-align: left;
            @include fontsize(14);
            @include xs_font03;
            span{
              margin-right: 20px;
            }
          }
          &:hover{
            div{
              img{
                filter : grayscale(0%);
                opacity: 1;
                @include transition01;

              }
            }
          }
        }
      }
    }
    .box02{
      margin-top: 105px;

    }
  }//inner
}//top_news_out

.top_last_content{
  width: 100%;
  text-align: center;
  height: 248px;
  background-color: #000;
  position: relative;
  z-index: 0;
  overflow: hidden;
  padding-left: 30px;
  padding-right: 30px;
  box-sizing: border-box;
  &:after{
    background-image: url(/assets/images/top/back01.jpg);
    background-size: cover;
    background-position: center;
    position: absolute;
    top: 0;
    left: 0;
    content:"";
    display: block;
    width: 100%;
    height: 100%;
    z-index: 1;
    box-sizing: border-box;
    transform: scale(1);
    @include transition_kuyousai;
  }
  &:before{
    position: absolute;
    content:"";
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,0.6);
    opacity: 0;
    @include transition_kuyousai;
    z-index: 2;
    box-sizing: border-box;
  }
  &:hover{
    &:before{
      opacity: 1;
      @include transition_kuyousai;
    }
    &:after{
      transform: scale(1.07, 1.07);
      @include transition_kuyousai;
    }
  }
  @media #{$size_1000}{
    height: auto;
    padding-top: 80px;
    padding-bottom: 80px;
  }
  a{
    width: 100%;
    height: 100%;
    padding-left: 30px;
    padding-right: 30px;
    box-sizing: border-box;
    display: block;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    position: relative;
    z-index: 3;
    @media #{$size_1000}{
      padding-top: 80px;
      padding-bottom: 80px;
      display: block;
      margin: 0 auto;
    }
    .box01{
      box-sizing: border-box;
      width: 100%;
      height: 100%;
      position: absolute;
      z-index: 3;
      top: 0px;
      left: 0;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;

      .inner{
        @media #{$size_1000}{
          padding-top: 80px;
          padding-bottom: 80px;
        }

      }
    }
  }
  .inner{
    max-width: 680px;
    display: flex;
    flex-direction: row;
    justify-content:space-between;
    align-items: center;
    width: 100%;
    @media #{$size_1000}{
      display: block;
      text-align: left;
    }
    h2{
      @include fontsize(24);
      @include xs_font03;
      color:#fff;
      @media #{$size_1000}{
        margin-bottom: 40px;
      }
    }
    p{
      @include fontsize(18);
      @include xs_font03;
      color:#fff;
      line-height: 1.7;
      @media #{$size_1000}{
        br{
          display: none;
        }
      }
    }
  }
}
